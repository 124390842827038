.summary-pdf-container {
  margin-top: var(--default-margin);
}

.summary-chapter-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.chapter-title-container,
.chapter-subtitle-container {
  display: flex;
  align-items: center;
  padding-top: 3px;
}

.chapter-title-container span,
.chapter-subtitle-container span {
  white-space: nowrap;
}

.chapter-title-container .chapter-title,
.chapter-subtitle-container .chapter-title {
  font-family: Outfit-SemiBold;
  font-size: 1.2rem;
}

.chapter-subtitle-container {
  padding-left: 20px;
}

.chapter-subtitle-container .subtitle-bullet-point {
  margin-right: 10px;
  font-size: 1.2rem;
}

.chapter-title-container .chapter-title,
.chapter-subtitle-container .chapter-title {
  flex: 1;
}

.chapter-title-container .summary-dots-line,
.chapter-subtitle-container .summary-dots-line {
  flex-grow: 0;
  text-align: center;
  overflow: hidden;
}

.page-break {
  page-break-before: always;
}
