.table-pdf-container {
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 5px;
  background-color: rgb(var(--table-color));
}
.table-pdf-container * {
  background-color: white;
}
.table-pdf-container tbody * {
  font-weight: 300;
}
.table-pdf-container thead th:first-child,
.table-pdf-container thead tr {
  border-top-left-radius: var(--border-radius);
}
.table-pdf-container thead th:last-child,
.table-pdf-container thead tr {
  border-top-right-radius: var(--border-radius);
}
.table-pdf-container th:first-child,
.table-pdf-container td:first-child {
  border-left: none;
}
.table-pdf-container th:last-child,
.table-pdf-container td:last-child {
  border-right: none;
}
.table-pdf-container tr:last-child th,
.table-pdf-container tr:last-child td {
  border-bottom: none;
}
.table-pdf-container thead th {
  border-top: none;
  font-size: 1.1rem;
  background-color: rgb(var(--table-color));
  color: var(--table-secondary-color);
}
.table-pdf-container th,
.table-pdf-container td {
  padding: 10px 6px;
  z-index: 2;
  text-align: center;
  border: 1px solid rgb(var(--table-color));
}
.table-pdf-container tr th {
  text-align: center;
}
.table-pdf-container .cell-dark-color-background *,
.table-pdf-container .cell-color-background * {
  border-left-color: rgb(var(--table-secondary-color));
  border-right-color: rgb(var(--table-secondary-color));
  background-color: rgba(var(--table-color), 0.7);
  color: var(--table-secondary-color);
}
.table-pdf-container .cell-color-background * {
  background-color: rgba(var(--table-color), 0.5);
}
.table-pdf-container .full-length-row * {
  background-color: rgb(var(--table-color));
  color: var(--table-secondary-color);
  font-weight: 400;
}
.table-pdf-container .cell-light-color-background * {
  background-color: rgba(var(--table-color), 0.3);
}
.table-paginator {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 5px;
}
