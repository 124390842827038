.form-error {
  height: 20px;
  position: relative;
  left: 30px;
  font-family: Outfit-SemiBold;
  width: fit-content;
  display: flex;
  gap: 5px;
  z-index: 2;
  position: absolute;
  padding: 2px 2px 5px;
}
.form-error::before {
  content: '';
  background-color: var(--red);
  z-index: -1;
  position: absolute;
  width: calc(100% + 10px);
  border-radius: 10px;
  left: -5px;
  top: 0px;
  height: 100%;
}
.form-error-container {
  display: flex;
  justify-content: center;
  color: var(--red);
  height: 20px;
  margin: 3px auto;
}
.form-error-container * {
  color: var(--red);
}
.form-error p {
  font-family: Outfit-SemiBold;
  font-size: 0.7rem;
  color: var(--white);
}
.form-error p:first-letter {
  text-transform: capitalize;
}
.form-label {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-label,
.form-label div,
.form-label span,
.form-label a,
.form-label p {
  color: var(--main-color);
  line-height: 0.9;
  font-family: Outfit-SemiBold;
  font-variant: all-small-caps;
  text-transform: lowercase;
}
.field-container {
  height: calc(100% - 10px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 5px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  transition:
    margin-top 0.3s,
    background-color 0.3s,
    opacity 0.3s,
    border 0.3s,
    box-shadow 0.3s;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}
.field-container input {
  background-color: transparent;
}
.field-container:focus-within {
  border-color: #ddd;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}
.field-container input,
.field-container select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--secondary-color);
  outline: 0;
  height: 30px;
  border-radius: 0;
  width: 100%;
}
.field-container input:focus,
.field-container select:focus {
  border-bottom: 2px solid var(--grey);
}
.borderless-fieldset {
  --form-background-color: var(--white);

  background-color: var(--form-background-color);
  position: relative;
  padding: 20px 30px 30px 30px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: none;
  margin: 1rem 0;
}
fieldset h2,
.form-h2 {
  display: flex;
  gap: 10px;
  padding: 10px 10px 25px;
  text-transform: uppercase;
}
.full-page-form {
  margin: 40px auto;
  max-width: 800px;
  padding: 30px;
}
.form-subtitle {
  padding-left: 10px;
  font-size: 0.8rem;
  font-family: Poppins-Medium;
  color: var(--secondary-color);
  position: relative;
  top: -10px;
  display: flex;
  gap: 5px;
}
.form-subtitle div {
  display: flex;
}
.form-legend {
  padding-left: 10px;
  font-style: italic;
  bottom: 30px;
  font-size: 0.8rem;
  font-family: Poppins-Bold;
  left: 30px;
}
.borderless-fieldset .w-100 {
  transition: max-height 0.3s;
  margin-top: auto;
}
.label-tip {
  color: var(--secondary-color);
  font-family: Poppins-Medium;
  font-size: 0.7rem;
  position: relative;
  top: -4px;
}

@media (max-width: 576px) {
  .borderless-fieldset {
    padding: 10px;
  }
  fieldset h2,
  .form-h2 {
    padding: 10px;
  }
}
