.rounded-div {
  width: var(--width);
  height: var(--height);
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  transform: rotate(var(--rotate));
}
.rounded-div:before {
  z-index: -1;
  content: '';
  width: var(--radius);
  height: var(--radius);
  border-radius: 50%;
  background-color: var(--bg-color);
  position: absolute;
  top: 0;
}
