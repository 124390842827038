.normal-bold-text-pdf span {
  font-weight: 700;
}
.normal-bold-text-pdf {
  margin-top: 10px;
  text-align: justify;
}
.normal-bold-texts-container-pdf {
  margin-top: var(--default-margin);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
