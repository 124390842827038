.pdf-container {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.pdf-container * {
  font-family: var(--font);
  color: var(--text-color);
  font-variant: normal;
  font-style: normal;
  font-weight: 400;
}
.page-break {
  page-break-before: always;
}
.doughnut-chart {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.doughnut-chart img {
  width: 400px;
  height: 400px;
}
.dots-list {
  --color: var(--secondary-color);
  padding-left: 25px;
}
.dots-list li,
.article-container li {
  position: relative;
  margin-bottom: 5px;
  text-align: start;
}
.final-pdf {
  position: relative;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  transform-origin: top;
}
.dots-list li:before,
.article-container li:before {
  content: '';
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik02LjY2NjY4IDEwLjAwMDdDOC41MDc2MyAxMC4wMDA3IDkuOTk5OTkgOC41MDgzIDkuOTk5OTkgNi42NjcyOEM5Ljk5OTk5IDQuODI2MzYgOC41MDc2MyAzLjMzMzk4IDYuNjY2NjggMy4zMzM5OEM0LjgyNTcgMy4zMzM5OCAzLjMzMzMyIDQuODI2MzYgMy4zMzMzMiA2LjY2NzI4QzMuMzMzMzIgOC41MDgzIDQuODI1NyAxMC4wMDA3IDYuNjY2NjggMTAuMDAwN1oiIGZpbGw9IiM4OTExNDciLz4NCjxwYXRoIGQ9Ik02LjY2NjY4IDBDNS4zNDgxMyAwIDQuMDU5MiAwLjM5MDk4OSAyLjk2Mjg3IDEuMTIzNTNDMS44NjY1NSAxLjg1NjA4IDEuMDEyMDYgMi44OTcyNyAwLjUwNzQ3NyA0LjExNTQ0QzAuMDAyODg3NjQgNS4zMzM2MiAtMC4xMjkxMzQgNi42NzQwMyAwLjEyODEwMiA3Ljk2NzI3QzAuMzg1MzM5IDkuMjYwNSAxLjAyMDI4IDEwLjQ0ODQgMS45NTI2MyAxMS4zODA3QzIuODg0OTcgMTIuMzEzMSA0LjA3Mjg3IDEyLjk0OCA1LjM2NjA3IDEzLjIwNTJDNi42NTkyNyAxMy40NjI1IDcuOTk5NyAxMy4zMzA1IDkuMjE3ODggMTIuODI1OUMxMC40MzYxIDEyLjMyMTMgMTEuNDc3MiAxMS40NjY4IDEyLjIwOTggMTAuMzcwNUMxMi45NDIzIDkuMjc0MTcgMTMuMzMzNCA3Ljk4NTIzIDEzLjMzMzQgNi42NjY2OEMxMy4zMzM0IDUuNzkxMTkgMTMuMTYwOSA0LjkyNDI4IDEyLjgyNTkgNC4xMTU0NEMxMi40OTA4IDMuMzA2NiAxMS45OTk4IDIuNTcxNjggMTEuMzgwNyAxLjk1MjYyQzEwLjc2MTcgMS4zMzM1NiAxMC4wMjY4IDAuODQyNTAyIDkuMjE3ODggMC41MDc0NjlDOC40MDkwOCAwLjE3MjQzNiA3LjU0MjE4IDAgNi42NjY2OCAwWk02LjY2NjY4IDExLjMzMzNDNS43NDM3IDExLjMzMzMgNC44NDE0NCAxMS4wNTk2IDQuMDc0MDEgMTAuNTQ2OEMzLjMwNjU4IDEwLjAzNDEgMi43MDg0NCA5LjMwNTIzIDIuMzU1MjQgOC40NTI1QzIuMDAyMDIgNy41OTk3OCAxLjkwOTYxIDYuNjYxNTIgMi4wODk2NyA1Ljc1NjI0QzIuMjY5NzQgNC44NTEgMi43MTQyIDQuMDE5NDggMy4zNjY4NCAzLjM2Njg0QzQuMDE5NDggMi43MTQxOSA0Ljg1MSAyLjI2OTczIDUuNzU2MjUgMi4wODk2N0M2LjY2MTUyIDEuOTA5NiA3LjU5OTc4IDIuMDAyMDEgOC40NTI1IDIuMzU1MjNDOS4zMDUyMyAyLjcwODQ0IDEwLjAzNDEgMy4zMDY1NyAxMC41NDY4IDQuMDc0MDFDMTEuMDU5NiA0Ljg0MTQzIDExLjMzMzMgNS43NDM2OSAxMS4zMzMzIDYuNjY2NjhDMTEuMzMzMyA3LjkwNDM2IDEwLjg0MTcgOS4wOTEzNCA5Ljk2NjU0IDkuOTY2NDdDOS4wOTEzNCAxMC44NDE3IDcuOTA0MzYgMTEuMzMzMyA2LjY2NjY4IDExLjMzMzNaIiBmaWxsPSIjODkxMTQ3IiBmaWxsLW9wYWNpdHk9IjAuNiIvPg0KPC9zdmc+DQo=');
  /*-webkit-mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik02LjY2NjY4IDEwLjAwMDdDOC41MDc2MyAxMC4wMDA3IDkuOTk5OTkgOC41MDgzIDkuOTk5OTkgNi42NjcyOEM5Ljk5OTk5IDQuODI2MzYgOC41MDc2MyAzLjMzMzk4IDYuNjY2NjggMy4zMzM5OEM0LjgyNTcgMy4zMzM5OCAzLjMzMzMyIDQuODI2MzYgMy4zMzMzMiA2LjY2NzI4QzMuMzMzMzIgOC41MDgzIDQuODI1NyAxMC4wMDA3IDYuNjY2NjggMTAuMDAwN1oiIGZpbGw9IiM4OTExNDciLz4NCjxwYXRoIGQ9Ik02LjY2NjY4IDBDNS4zNDgxMyAwIDQuMDU5MiAwLjM5MDk4OSAyLjk2Mjg3IDEuMTIzNTNDMS44NjY1NSAxLjg1NjA4IDEuMDEyMDYgMi44OTcyNyAwLjUwNzQ3NyA0LjExNTQ0QzAuMDAyODg3NjQgNS4zMzM2MiAtMC4xMjkxMzQgNi42NzQwMyAwLjEyODEwMiA3Ljk2NzI3QzAuMzg1MzM5IDkuMjYwNSAxLjAyMDI4IDEwLjQ0ODQgMS45NTI2MyAxMS4zODA3QzIuODg0OTcgMTIuMzEzMSA0LjA3Mjg3IDEyLjk0OCA1LjM2NjA3IDEzLjIwNTJDNi42NTkyNyAxMy40NjI1IDcuOTk5NyAxMy4zMzA1IDkuMjE3ODggMTIuODI1OUMxMC40MzYxIDEyLjMyMTMgMTEuNDc3MiAxMS40NjY4IDEyLjIwOTggMTAuMzcwNUMxMi45NDIzIDkuMjc0MTcgMTMuMzMzNCA3Ljk4NTIzIDEzLjMzMzQgNi42NjY2OEMxMy4zMzM0IDUuNzkxMTkgMTMuMTYwOSA0LjkyNDI4IDEyLjgyNTkgNC4xMTU0NEMxMi40OTA4IDMuMzA2NiAxMS45OTk4IDIuNTcxNjggMTEuMzgwNyAxLjk1MjYyQzEwLjc2MTcgMS4zMzM1NiAxMC4wMjY4IDAuODQyNTAyIDkuMjE3ODggMC41MDc0NjlDOC40MDkwOCAwLjE3MjQzNiA3LjU0MjE4IDAgNi42NjY2OCAwWk02LjY2NjY4IDExLjMzMzNDNS43NDM3IDExLjMzMzMgNC44NDE0NCAxMS4wNTk2IDQuMDc0MDEgMTAuNTQ2OEMzLjMwNjU4IDEwLjAzNDEgMi43MDg0NCA5LjMwNTIzIDIuMzU1MjQgOC40NTI1QzIuMDAyMDIgNy41OTk3OCAxLjkwOTYxIDYuNjYxNTIgMi4wODk2NyA1Ljc1NjI0QzIuMjY5NzQgNC44NTEgMi43MTQyIDQuMDE5NDggMy4zNjY4NCAzLjM2Njg0QzQuMDE5NDggMi43MTQxOSA0Ljg1MSAyLjI2OTczIDUuNzU2MjUgMi4wODk2N0M2LjY2MTUyIDEuOTA5NiA3LjU5OTc4IDIuMDAyMDEgOC40NTI1IDIuMzU1MjNDOS4zMDUyMyAyLjcwODQ0IDEwLjAzNDEgMy4zMDY1NyAxMC41NDY4IDQuMDc0MDFDMTEuMDU5NiA0Ljg0MTQzIDExLjMzMzMgNS43NDM2OSAxMS4zMzMzIDYuNjY2NjhDMTEuMzMzMyA3LjkwNDM2IDEwLjg0MTcgOS4wOTEzNCA5Ljk2NjU0IDkuOTY2NDdDOS4wOTEzNCAxMC44NDE3IDcuOTA0MzYgMTEuMzMzMyA2LjY2NjY4IDExLjMzMzNaIiBmaWxsPSIjODkxMTQ3IiBmaWxsLW9wYWNpdHk9IjAuNiIvPg0KPC9zdmc+DQo=');*/
  fill: var(--secondary-color);
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: var(--secondary-color);
  background-repeat: no-repeat;
  left: -21px;
  top: 6px;
}
.rh-table-legend {
  display: flex;
  align-items: center;
  margin: var(--default-margin) auto;
  gap: 10px;
  font-weight: 300;
  font-size: 0.9rem;
}
.rh-table-legend div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(var(--table-color), 0.7);
}
.top-scroller-container {
  position: sticky;
  bottom: 35px;
  width: fit-content;
  margin-left: auto;
  margin-right: -100px;
}
.top-scroller {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: var(--main-color);
  cursor: pointer;
  margin: auto;
  transition: transform 0.3s;
}
.top-scroller:hover {
  transform: translateY(-10px);
}
.top-scroller:before,
.top-scroller:after {
  content: '';
  position: absolute;
  top: 47%;
  left: 61.5%;
  width: 25px;
  height: 5px;
  border-radius: 2px;
  background-color: var(--white);
  transform: translate(-50%, -50%) rotate(45deg);
}
.top-scroller:after {
  left: 38.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.default-margin-placeholder {
  margin-top: var(--default-margin);
  height: 0;
  width: 100%;
}
.pdf-p {
  margin-top: var(--default-margin);
  text-align: justify;
}
.pdf-business-image-container {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.pdf-business-img {
  max-width: 500px;
  height: 400px;
  object-fit: cover !important;
  border-radius: var(--border-radius) !important;
}
.pdf-download-container {
  position: fixed;
  left: 50%;
  bottom: 5vh;
  transform: translateX(-50%);
  z-index: 10;
}
.pdf-margin-placeholder {
  margin-top: var(--default-margin);
  height: 0;
  width: 100%;
}
.generator-container {
  position: absolute;
  opacity: 0;
  width: fit-content;
  left: 0;
}
.pdf-generated .generator-container {
  display: none;
}
.final-pdf-container {
  margin: auto;
}

.text-asterisk-valeurs-tds{
  font-size: 0.8rem;
  font-style: italic;
  text-align: justify;
}

@media (max-width: 1200px) {
  .top-scroller-container {
    margin-right: 20px;
  }
}

@media (max-width: 900px) {
  .final-pdf {
    transform: scale(0.85);
  }
  .top-scroller-container {
    margin-right: 70px;
  }
  .final-pdf-container {
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
}

@media (max-width: 810px) {
  .final-pdf {
    transform: scale(0.7);
  }
}

@media (max-width: 700px) {
  .final-pdf {
    transform: scale(0.6);
  }
  .top-scroller {
    width: 50px;
    height: 50px;
  }
  .top-scroller:after {
    width: 20px;
  }
  .top-scroller:before {
    width: 20px;
  }
  .top-scroller-container {
    margin-right: 180px;
  }
}
