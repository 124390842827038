.checkbox-container {
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  padding-bottom: 15px;
}
.checkbox-container input {
  margin-top: 2px;
  width: 15px;
  height: 15px;
  appearance: none;
  border: 2px solid var(--grey) !important;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  background-color: var(--white);
}
.checkbox-container input {
  margin-top: 2px;
  min-width: 15px;
  width: 15px;
  min-height: 15px;
  height: 15px;
  cursor: pointer;
}
.checkbox-container input:checked {
  background-color: var(--grey);
  border-color: var(--grey) !important;
}
.checkbox-check {
  position: absolute;
  cursor: pointer;
  top: 15px;
  display: flex;
  left: 13px;
}
.checkbox-check * {
  cursor: pointer !important;
}
.checkbox-error-container {
  position: relative;
}
.checkbox-error-container .form-error {
  bottom: 0px;
}
