.pdf-container .strong-weak-points-h4 {
  color: var(--main-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
}
.strong-weak-point-title-pdf {
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  gap: 3px;
  align-items: center;
}

.arrow-weak-rotate {
  transform: rotate(90deg) !important;
}
