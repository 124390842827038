.pagination-container {
  display: flex;
  justify-content: end;
  font-size: 1.2rem;
  color: var(--main-color) !important;
}

.pagination-hr-separator {
  position: absolute;
  width: 60px;
  height: 4px;
  bottom: 30px;
  right: 0px;
  background: linear-gradient(to right, transparent, var(--main-color));
  border: none;
}
