.steps-group-container {
  padding: 10px 0;
  box-shadow: 0px 5px 17px -3px rgba(123, 123, 123, 0.25);
  position: relative;
  overflow: hidden;
}
.steps-group-slider {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.steps-subgroup-title {
  font-family: Outfit-SemiBold;
  font-size: 1.3rem;
  margin-bottom: 5px;
  font-variant: all-small-caps;
  text-transform: lowercase;
  text-align: center;
}
.steps-subgroup-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0;
}
.steps-subgroup {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.step-check,
.step .step-check *,
.step * {
  cursor: pointer !important;
}
.step-check,
.step {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.step-check {
  opacity: 0;
  transition:
    opacity 0.3s,
    transform 0.3s;
  position: absolute;
  background-color: var(--main-color);
}
.step-check.done {
  opacity: 1;
}
.step.current .step-check,
.step.current {
  transform: scale(1.1);
  background-color: var(--secondary-color);
}
.step {
  color: var(--white);
  background-color: var(--grey);
  transition:
    background-color 0.3s,
    transform 0.3s;
}
.step::before {
  content: '';
}
.step:hover {
  background-color: var(--secondary-color);
}
.step-check:hover {
  opacity: 0;
}
.steps-row-bracket {
  width: 40px;
  position: absolute;
  top: 60px;
  left: 30px;
}
.steps-row-bracket:last-child {
  left: auto;
  right: 30px;
}

@media (max-width: 992px) {
  .steps-group-container {
    padding: 0 0 5px;
  }
  .steps-subgroup-container {
    min-width: 100vw;
  }
  .steps-group-slider {
    gap: 0;
    transition: right 0.3s;
    position: relative;
    right: calc(var(--slide-position) * 100vw);
    justify-content: flex-start;
  }
}

@media (max-width: 576px) {
  .steps-subgroup-container {
    gap: 15px;
  }
  .steps-row-bracket {
    top: 7px;
  }
}
