.rh-input-container {
  display: flex;
  padding-right: 4px;
  column-gap: 10px;
  align-items: center;
}
.rh-input-container > div:nth-child(3),
.rh-input-container > div:nth-child(4),
.rh-input-container > div:nth-child(5) {
  width: 60% !important;
}
.rh-input-container .buttons-container-plan-financement {
  width: auto;
}
.parcours-small-button {
  display: flex;
  position: relative;
  gap: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  justify-content: flex-end;
  height: 59px;
}
.parcours-small-button svg {
  background-color: var(--light-grey);
  border-radius: 5px;
  overflow: visible;
  padding: 3px;
  border: 3px var(--grey) solid;
}
.rh-inputs-list-container {
  max-height: 360px;
  padding-right: 5px;
  margin-bottom: 15px;
  overflow-y: scroll;
}
.rh-card {
  border-radius: 10px;
  padding: 10px;
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 15px;
}
.rh-card .w-100 {
  transition: 0.3s all ease;
}
.rh-card:last-child {
  margin-bottom: 0;
}
.invisible-rh-card {
  padding: 0 !important;
  margin: 0 !important;
}

@media (max-width: 1200px) {
  .rh-input-container {
    flex-wrap: wrap;
  }
  .rh-input-container > div:nth-child(1),
  .rh-input-container > div:nth-child(2) {
    width: calc(50% - 5px) !important;
  }
  .rh-input-container > div:nth-child(3),
  .rh-input-container > div:nth-child(4),
  .rh-input-container > div:nth-child(5) {
    width: calc(30% - 2px) !important;
  }
  .rh-card {
    padding: 15px;
    box-shadow: none;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  .rh-inputs-list-container {
    max-height: 450px;
  }
}

@media (max-width: 576px) {
  .rh-input-container > div:nth-child(1),
  .rh-input-container > div:nth-child(2) {
    width: 100% !important;
  }
  .rh-input-container > div:nth-child(3),
  .rh-input-container > div:nth-child(4),
  .rh-input-container > div:nth-child(5) {
    width: calc(50% - 5px) !important;
  }
  .rh-card {
    padding: 10px 3px;
  }
  .trash-container {
    margin: auto;
  }
}
