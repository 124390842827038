.circle-growth-container {
  margin-top: var(--default-margin);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.circle-growth-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-growth-percentage-amount {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 15px solid var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: var(--main-color) !important;
}

.circle-growth-text {
  max-width: 300px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.9rem;
}
