.filters-group-container {
  box-shadow: 3px 3px 20px rgba(123, 123, 123, 0.25);
  padding: 5px 0;
  margin-bottom: 10px;
  background-color: var(--creamy-white);
}
.filters-group-container .container > div {
  justify-content: center;
  margin: auto;
  display: flex;
  gap: 20px;
}
.filter {
  color: var(--main-color);
  font-size: 0.9rem;
  font-family: Outfit-SemiBold;
  border: 2px solid var(--main-color);
  border-radius: 3px;
  padding: 6px 15px 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition:
    color 0.3s,
    background-color 0.3s;
  white-space: nowrap;
}
.filter:before {
  display: block;
  content: attr(custom-title);
  font-family: Outfit-Black;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.filter:hover,
.filter.active {
  color: var(--white);
  background-color: var(--main-color);
}
.filters-group-container *::-webkit-scrollbar {
  height: 8px;
}
.filters-group-container *::-webkit-scrollbar-track {
  margin: 10px;
}
.filters-group-container .container {
  max-width: min(var(--container-width), calc(100% - 100px));
}
@media (min-width: 992px) {
  .filters-group-container .components-slider {
    gap: 20px;
  }
  .filters-group-container .components-row {
    padding-left: 2px;
  }
}

@media (max-width: 992px) {
  .filters-group-container .container {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
  }
  .filter.active {
    padding: 6px 6px 5px;
    opacity: 1;
  }
  .filter:hover,
  .filter.active {
    background-color: unset;
    color: var(--main-color);
  }
  .filters-group-container.pinned {
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);
  }
  .filters-group-container .container {
    margin: 0;
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 10px;
    gap: 20px;
    box-shadow: none;
    margin: auto;
    width: fit-content;
  }
  .filter {
    border: none;
    opacity: 0.6;
  }
}
