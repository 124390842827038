.estimation-details-container-pdf {
  margin-top: var(--default-margin);
}
.estimation-details-title {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}
.estimation-details-subtitle {
  text-align: center;
}
.value-estimation-details {
  margin: 13px 0 0 !important;
}
