.code-input-container {
  display: flex;
  justify-content: center;
  gap: 3px;
  margin: 10px auto 0;
  position: relative;
  width: fit-content;
}
.code-input-container .field-container,
.code-input-container .w-100 {
  width: 40px !important;
  gap: 0;
}
.code-input-container input {
  padding: 0 5px;
  width: 20px;
}
.code-input-container.with-error input {
  border-color: var(--red);
}
.code-input-container .form-error {
  width: calc(100% - 30px);
  bottom: 1px;
  left: 16px;
  top: unset;
  justify-content: center;
}
.code-input-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.code-input-subtexts {
  display: flex;
  justify-content: space-between;
}
.confirm-inscription-send-code {
  text-decoration: underline;
  color: var(--main-color);
  cursor: pointer;
}
.confirm-inscription-code-sent {
  color: var(--main-color);
}
.confirm-inscription-error {
  justify-content: center;
  color: var(--red);
  margin: 10px 0;
}
