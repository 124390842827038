.text-3xl {
  font-size: 3.5rem !important;
}
.text-xxl {
  font-size: 2.5rem !important;
}
.text-xl {
  font-size: 1.5rem !important;
}
.text-lg {
  font-size: 1.2rem !important;
}
.text-md {
  font-size: 1rem !important;
}
.text-sm {
  font-size: 0.9rem !important;
}
.text-xs {
  font-size: 0.8rem !important;
}

.line-height-lg {
  line-height: 30px;
}

.text-orange {
  color: var(--secondary-color) !important;
}
.text-blue {
  color: var(--main-color) !important;
}
.text-gold {
  color: var(--grey) !important;
}
.text-dark-blue {
  color: var(--grey) !important;
}
.text-grey {
  color: var(--grey) !important;
}
.text-light-gold {
  color: var(--grey) !important;
}
.text-red {
  color: var(--red) !important;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}

.light {
  font-weight: 300 !important;
}
.bold {
  font-weight: 600 !important;
}
.bolder {
  font-weight: 700 !important;
}
.line-through {
  text-decoration: line-through;
}

.lowercase {
  text-transform: lowercase !important;
}
.span-inherit {
  color: inherit;
  font-size: inherit;
}
.text-underline {
  text-decoration: underline;
}
.h2-uppercase {
  text-transform: uppercase;
  margin: 15px 0 20px;
  font-family: Outfit-Bold;
  font-size: 1.1rem;
}

.big-text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.big-text-container p,
.big-text-container h4,
.big-text-container h3,
.big-text-container ul {
  width: 90ch;
  max-width: 100%;
  margin: auto;
}
.text-justify {
  text-align: justify;
}
