.access-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--main-color);
}

.logo-access-page {
  width: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.access-page h1 {
  margin-bottom: 20px;
  color: white;
}

.access-page input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.access-page button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #F29554;
  color: white;
  cursor: pointer;
}

.access-page button:hover {
  background-color: white;
  color: black;
}
