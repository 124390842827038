.card-img {
  max-width: 30%;
  object-fit: contain;
  width: auto;
  max-height: 110px;
}
.visiting-card-container {
  max-width: 700px;
  width: 100%;
  height: 400px;
  max-height: 400px;
  padding: 20px;
  z-index: 2;
  border: solid 3px var(--main-color);
  background-color: var(--light-grey);
  border-radius: 15px;
}
.imported-card-container {
  width: 100%;
  max-width: 700px;
}
.imported-card-container.pdf-visiting-card {
  margin: 30px auto;
}
.visiting-card-container.pdf-visiting-card {
  margin: 0 auto;
  border: solid 3px var(--grey);
  background-color: transparent;
}
.pdf-visiting-card {
  margin-bottom: var(--default-margin);
}
.visiting-card-container * {
  line-height: 1.4;
}
.card-top,
.card-bottom {
  display: flex;
  gap: 20px;
}
.card-bottom {
  justify-content: flex-end;
}
.card-top > div,
.card-bottom > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.visiting-card-container svg {
  --color: var(--main-color) !important;

  width: 20px;
  height: 20px;
}
.card-mid {
  margin: 20px 0 5px;
}
.card-mid-content {
  display: flex;
  gap: 15px;
}
.card-name span {
  font-family: Outfit-Bold;
  font-size: 2.2rem;
}
.card-position {
  font-size: 1.3rem;
  font-family: Outfit-SemiBold;
}
.visiting-card-container .card-slogan {
  color: var(--main-color);
  font-size: 1.3rem;
  font-family: Outfit-Bold;
}
.card-bottom * {
  text-align: right;
}
.card-company {
  font-size: 1.8rem;
  font-family: Outfit-Bold;
}

@media (max-width: 576px) {
  .visiting-card-container {
    padding: 10px;
  }
  .visiting-card-container * {
    font-size: 0.8rem;
  }
  .card-top,
  .card-bottom,
  .card-mid-content {
    gap: 5px;
  }
  .card-name span {
    font-size: 1.1rem;
  }
  .card-img {
    max-height: 70px;
  }
}
