.first-input {
  width: 100% !important;
  padding: 10px !important;
}
.double-input-container {
  gap: 15px !important;
}
.first-input::placeholder {
  color: var(--grey-dark);
  font-style: italic;
}
.double-input-container .w-100 .form-error {
  width: fit-content;
  left: 20px;
  bottom: -20px;
}
