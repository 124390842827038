.card-with-headers {
  border-radius: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  border: 3px solid var(--border-color);
  height: 100%;
  position: relative;
}
.card-content {
  padding: 20px 30px;
}
.card-header,
.card-footer {
  padding: 20px;
  background-color: var(--border-color);
}
.card-footer {
  padding: var(--padding) 20px;
}
.card-header h2,
.card-footer h2 {
  color: white;
  justify-content: center;
  margin: 0;
}
.card-footer {
  margin-top: auto;
}
