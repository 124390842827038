.liste-materiel-container {
  width: 100%;
  margin: var(--default-margin) 0;
}

.liste-materiel-row {
  display: flex;
  margin-top: 20px;
  padding: 0;
}

.liste-materiel-item {
  display: flex;
}

.liste-materiel-intitule {
  font-weight: bold;
  white-space: nowrap;
  margin-right: 4px;
}
