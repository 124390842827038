.sticky-searchbar-container {
  --top: 0;
  position: sticky;
  top: var(--top);
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: var(--margin);
  height: 80px;
  transition: top 0.3s;
}

.sticky-searchbar-container .search-bar-container {
  max-width: 300px;
  width: 100%;
  margin: 0 20px 0 0;
  display: flex;
  justify-content: center;
}

.filtre-article-button {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  height: 40px;
}

@media (max-width: 992px) {
  .sticky-searchbar-container {
    margin-bottom: 0;
  }
}
