.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  padding: var(--padding);
  cursor: pointer;
  font-family: Outfit-SemiBold;
}
.link-with-arrow {
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: transform 0.3s;
}
.link-with-arrow:hover svg {
  --color: var(--main-color) !important;
}
.link-with-arrow:hover {
  color: var(--main-color);
  transform: translateX(10px);
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
}
.default-button-settings {
  --border-radius: 10px;
  --background-color: var(--main-color);
  --hover-color: var(--main-color);
  --color: var(--white);
  --padding: 5px 20px;
}
.inversion-button svg {
  --color: var(--hover-color);
}
.inversion-button {
  background-color: var(--bg-color);
  border: 3px solid var(--bg-color);
  color: var(--hover-color);
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;
}
.inversion-button:hover svg {
  --color: var(--bg-color) !important;
}
.inversion-button:hover {
  color: var(--bg-color);
  background-color: var(--hover-color);
  border-color: var(--hover-color);
}
.mid-page-button {
  --padding: 10px 25px !important;
  --font-size: 1.3rem !important;

  margin: 90px auto;
  width: fit-content;
}

@media (max-width: 1200px) {
  .mid-page-button {
    --font-size: 1.1rem !important;

    margin: 60px auto;
  }
}
