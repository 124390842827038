@font-face {
  font-family: 'Outfit-Bold';
  src:
    local('Outfit-Bold'),
    url('../../assets/fonts/Outfit-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit-Black';
  src:
    local('Outfit-Black'),
    url('../../assets/fonts/Outfit-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit-SemiBold';
  src:
    local('Outfit-SemiBold'),
    url('../../assets/fonts/Outfit-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit-Medium';
  src:
    local('Outfit-Medium'),
    url('../../assets/fonts/Outfit-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src:
    local('Poppins-Bold'),
    url('../../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src:
    local('Poppins-BoldItalic'),
    url('../../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src:
    local('Poppins-Light'),
    url('../../assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src:
    local('Poppins-LightItalic'),
    url('../../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src:
    local('Poppins-Medium'),
    url('../../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src:
    local('Poppins-MediumItalic'),
    url('../../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: normal;
  src:
    local('Roboto-Black'),
    url('../../assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src:
    local('Roboto-Bold'),
    url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src:
    local('Roboto-Italic'),
    url('../../assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src:
    local('Roboto-Medium'),
    url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src:
    local('Roboto-Light'),
    url('../../assets/fonts/Roboto-Light.ttf') format('truetype');
}

.outfit-black {
  font-family: Outfit-Black !important;
  font-style: normal !important;
}
.outfit-medium {
  font-family: Outfit-Medium !important;
  font-style: normal !important;
}
.outfit-semibold {
  font-family: Outfit-SemiBold !important;
  font-style: normal !important;
}
.outfit-bold {
  font-family: Outfit-Bold !important;
  font-style: normal !important;
}

.poppins-bold {
  font-family: Poppins-Bold !important;
  font-style: normal !important;
}
.poppins-medium {
  font-family: Poppins-Medium !important;
  font-style: normal !important;
}
.poppins-light {
  font-family: Poppins-Light !important;
  font-style: normal !important;
}

.poppins-bold-it {
  font-family: Poppins-BoldItalic !important;
  font-style: normal !important;
}
.poppins-medium-it {
  font-family: Poppins-MediumItalic !important;
  font-style: normal !important;
}
.poppins-light-it {
  font-family: Poppins-LightItalic !important;
  font-style: normal !important;
}

.roboto-black {
  font-family: Roboto !important;
  font-weight: 900 !important;
  font-style: normal !important;
}
.roboto-bold {
  font-family: Robot !important;
  font-weight: 700 !important;
  font-style: normal !important;
}
.roboto-medium {
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-style: normal !important;
}
.roboto-light {
  font-family: Roboto !important;
  font-weight: 300 !important;
  font-style: normal !important;
}
.roboto-italic {
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-style: italic !important;
}
