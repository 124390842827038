.page-pdf,
.pdf-generator-container {
  position: relative;
  width: 210mm;
  height: 297mm;
  background-color: #ffffff;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
}
.page-pdf .page-pdf-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pdf-generator-container {
  height: auto;
}
.page-pdf {
  position: relative;
  max-width: 1060px;
  height: 1122px;
  background-color: #ffffff;
  aspect-ratio: 1/1.4;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
}
.page-number {
  position: absolute;
  bottom: 30px;
  right: 40px;
}
.page-break {
  page-break-before: always;
}

.testtest {
  color: rgba(255, 255, 255, 0.8);
}
