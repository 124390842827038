.calculation-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}
.calculation-title {
  font-size: 1.3rem;
  font-weight: bold;
}
.calculation-title-sup {
  font-size: 0.6rem;
  color: var(--secondary-color) !important;
}
.calculation-subtitle {
  color: #666;
}
.calculation-values {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 15px 0;
}
.calculation-amount {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary-color) !important;
}
.calculation-amount-mid {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color) !important;
}
.calculation-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: bold;
}
.calculation-label svg {
  margin-left: 5px;
  margin-right: 5px;
}
.calculation-label-mid {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: center;
}
.calculation-hr-leftside {
  width: 100px;
  border: none;
  height: 4px;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  background: linear-gradient(-90deg, var(--secondary-color), transparent 100%);
}
.calculation-hr-rightside {
  width: 100px;
  border: none;
  height: 4px;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  background: linear-gradient(90deg, var(--secondary-color), transparent 100%);
}
.calculation-proportion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.calculation-proportion {
  font-size: 0.8rem;
  font-style: italic;
  color: var(--secondary-color);
  word-wrap: break-word;
  max-width: 450px;
  white-space: pre-wrap;
}