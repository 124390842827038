.text-h1-container {
  margin: auto;
  width: 90%;
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--main-color);
}

.text-h1-container h2 {
  font-family: Outfit-SemiBold;
  text-transform: uppercase;
  color: #fff;
}
