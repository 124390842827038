:root {
  --black: #000000;
  --white: #ffffff;
  --grey: #645e5e;
  --main-color: #1d2362;
  --secondary-color: #f29554;
  --light-blue: #6f9dd3;
  --light-orange: #f29554;
  --yellow: #fdcf60;
  --light-grey: #dddddd;
  --link-blue: #2727ff;
  --red: #ef3749;
  --green: #3ba216;
  --creamy-white: #fcf9f5;
}
