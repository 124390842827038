.multiple-inputs-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin: 10px 0;
}
.multiple-inputs-container .button {
  margin-left: auto;
}
.multiple-inputs-container .input-container .form-error {
  left: 5px;
  min-width: max-content;
}
.two-inputs-container {
  display: flex;
  width: 100%;
}

@media (max-width: 992px) {
  .two-inputs-container {
    flex-direction: column;
  }
}
