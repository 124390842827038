.estimations-buttons-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.estimations-buttons-row .button {
  margin: 0;
}
.estimations-container {
  border: 3px solid var(--main-color);
  background-color: white;
  border-radius: 15px;
  padding: 20px 30px;
  margin-top: 20px;
}
.estimations-container-header p {
  font-size: 1.3rem;
  display: inline;
  cursor: pointer;
}
.estimations-container-header p.active {
  font-family: Outfit-Bold;
  text-decoration: underline;
  text-decoration-color: var(--secondary-color);
}
.estimations-container-header p:first-child {
  margin-right: 50px;
}
.estimations-search-bar input {
  margin: 15px 0 !important;
  outline: var(--main-color) auto 1px;
  outline-offset: 0px;
}
.estimations-filters {
  margin-right: 14px;
  margin-bottom: -5px;
}
.estimations-filters,
.estimation-overview-container {
  display: grid;
  gap: 10px;
  padding: 0 10px;
  grid-template-columns: 3fr 3fr 2fr 1fr auto;
  grid-template-rows: 100%;
}
.estimation-overview-container {
  gap: 20px;
}
.estimations-filters .estimations-filters-buttons {
  margin-bottom: 10px;
}
.estimations-filters-buttons {
  justify-content: space-between;
  gap: 15px;
}
.estimations-filters-buttons .button {
  --color: var(--black) !important;
  --hover-color: var(--black) !important;
  --font-size: 0.8rem !important;
  --padding: 3px 5px !important;
  --border-radius: 5px !important;
}
.estimations-filters .centered:before {
  content: attr(custom-title);
  height: 0;
  display: block;
  padding: 0 8px;
  font-size: 0.8rem;
  overflow: hidden;
  visibility: hidden;
}
.estimations-filters div {
  display: flex;
  align-items: center;
  font-family: Outfit-Bold;
}
.estimations-table-body {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 300px;
  overflow-y: scroll;
}
.estimations-table-body::-webkit-scrollbar {
  width: 10px;
}
.estimation-overview-container {
  background-color: #f5f3ec;
  filter: drop-shadow(2px 3px 2px var(--light-grey));
  border-radius: 10px;
  padding: 10px;
  margin-right: 5px;
}
.estimation-overview-container div {
  color: var(--black);
  font-size: 0.9rem;
}
.estimation-overview-container div {
  display: flex;
  align-items: center;
  max-height: 30px;
  text-overflow: ellipsis;
}
.estimation-thumbnail-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}
.estimation-thumbnail {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.estimation-enseigne {
  color: var(--white);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  background-color: var(--grey);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.estimation-infos {
  text-align: center;
  margin-top: 20px;
}
.estimation-infos p {
  margin-bottom: 5px;
}
.estimation-infos .label {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-family: Outfit-Bold;
}
.estimation-buttons {
  display: flex;
  justify-content: space-between;
  margin: auto 25px 20px;
  padding-top: 20px;
}
.estimation-buttons .button {
  --padding: 8px !important;

  margin: 0 auto 5px;
}
.estimation-buttons a,
.estimation-buttons div {
  text-align: center;
  font-size: 0.8rem;
}
.estimation-thumbnail .fake-button {
  padding: 8px;
  width: fit-content;
  margin: 0 auto 5px;
  border-radius: 10px;
  border: 3px solid transparent;
}
.estimation-thumbnail .fake-button div {
  left: -100%;
  top: -32px;
  bottom: unset;
}
.estimation-overview-container .fake-button,
.estimation-thumbnail .fake-button {
  color: rgb(177 175 173);
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 4px 8px;
  cursor: not-allowed;
  position: relative;
  font-family: Outfit-SemiBold;
}
.fake-button div {
  display: flex;
  align-items: center;
  color: var(--grey);
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition:
    max-height 0.2s,
    padding 0.2s;
  background-color: var(--red);
  border-radius: 5px;
  bottom: 15px;
  padding: 0 11px;
  z-index: 3;
  width: max-content;
  left: -50%;
}
.fake-button:hover div {
  max-height: 30px;
  padding: 10px;
}

@media (max-width: 1200px) {
  .estimations-button {
    margin: 0 auto;
  }
  .estimations-container {
    background-color: unset;
  }
  .estimations-container-header {
    text-align: center;
  }
  .estimations-search-bar {
    max-width: 70%;
    margin: 15px auto -15px;
    width: fit-content;
  }
  .estimations-search-bar input {
    max-width: 100%;
  }
  .estimations-search-bar.search-bar-container .searchbar-cross {
    right: 14px;
  }
  .estimation-overview-container .fake-button,
  .estimation-thumbnail .fake-button {
    border-radius: 10px;
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .estimation-thumbnail-container {
    width: 45vw;
    margin: 20px 10px;
  }
  .estimation-thumbnail svg {
    width: 20px;
    height: 20px;
  }
  .estimation-buttons div,
  .estimation-buttons a {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .estimations-buttons-row {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .estimations-buttons-row .button {
    width: 100%;
  }
  .estimation-thumbnail-container {
    width: 80vw;
  }
  .estimations-container {
    margin-top: 20px;
    padding: 20px 0 0;
  }
  .estimations-container-header p {
    font-size: 1rem;
  }
  .estimations-container-header p:first-child {
    margin-right: 20px;
  }
  .estimations-search-bar {
    max-width: 100%;
    width: 100%;
  }
}
