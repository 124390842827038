input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  cursor: pointer;
  left: 5px;
}
input[type='date'] {
  padding-left: 30px;
}
.before-euro::before,
.before-percent::before {
  position: absolute;
  font-family: Outfit-SemiBold;
  font-variant: small-caps;
  top: 3.5px;
}
.before-euro::before {
  content: '€';
}
.before-percent::before {
  content: '%';
}
.before-euro input,
.before-percent input {
  padding-left: 20px;
}
.before-search input {
  padding-left: 25px;
}
.before-search-content {
  position: absolute;
  top: 3px;
}
.filled-input {
  background-color: var(--bg-color);
  color: var(--white) !important;
  border-radius: 10px;
  font-family: Outfit-Bold;
}
.filled-input * {
  border-color: var(--white) !important;
  color: var(--white);
}
.filled-input input[placeholder] {
  color: var(--white);
}
.input-container {
  width: 100%;
  position: relative;
  padding-bottom: 3px;
}
.input-container .form-error {
  left: 20px;
  bottom: -16px;
}
.input-container .form-error:before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.input-container.with-error input,
.number-input-container.with-error input,
.input-container.with-error select {
  border-bottom-color: var(--red);
}
.disabled-input input {
  border-bottom-color: transparent !important;
}
