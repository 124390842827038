.form-textarea {
  width: 100%;
  border: 2px solid var(--grey);
  border-radius: 6px;
  min-height: 200px;
  padding: 10px;
  resize: vertical;
  transition: all 0.2s;
  outline: 0;
}
.form-textarea:focus {
  border: 2px solid var(--grey);
}
.small-textarea .form-textarea {
  min-height: 100px;
}
.form-textarea.with-error {
  border-color: var(--red);
}
.textarea-container .form-error {
  bottom: -9px;
  left: 30px;
}
.textarea-container .form-error::before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
