.notifications-group-container {
  max-width: 25vw;
  position: fixed;
  top: calc(100vh - var(--max-height) - 20px);
  left: 0;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  gap: 10px;
  z-index: 10000;
  overflow: hidden;
}
.animated-notifications-group-container {
  transition: top 0.5s;
}
.notification-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  border-radius: 10px;
  background-color: var(--background-color);
  padding: 15px;
}
.notification-content,
.notification-content * {
  color: var(--color);
}
.fade-in {
  animation: fadeIn 0.3s;
  opacity: 1;
}
.slide-out {
  animation: slideOut 0.3s;
}

@media (max-width: 1200px) {
  .notifications-group-container {
    max-width: 100vw;
    width: 100vw;
    bottom: calc(100vh - var(--max-height) - 20px);
    top: auto;
    flex-direction: column;
  }
  .animated-notifications-group-container {
    transition: bottom 0.5s;
  }
  .notification-container {
    padding: 20px;
    border-radius: 10px;
  }
}

@media (max-width: 576px) {
  .notification-container {
    padding: 10px;
    border-radius: 8px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
