.modal-container {
  position: fixed;
  width: 100%;
  z-index: 9999;
  display: flex;
  height: 100vh;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: center;
  opacity: 1;
  top: 0;
  transition: opacity 0.3s ease;
}
.modal-background {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  background-color: black;
  opacity: 0.5;
}
.modal {
  margin: 10px;
  width: 500px;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  transform: translate(0, 0);
  transition: transform 0.15s ease;
  position: relative;
  top: 100px;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}
.modal-header {
  padding: 1rem 1rem;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
}
.modal-content {
  padding: 16px;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.5rem;
  font-family: Outfit-SemiBold;
  text-transform: uppercase;
}
.modal-hidden {
  opacity: 0;
}
.modal-hidden .modal {
  transform: translate(0, -5vh);
  opacity: 0;
}
.modal-buttons {
  display: flex;
  margin-top: 30px;
  gap: 10px;
}
.modal-buttons .button,
.modal-buttons div {
  width: 100%;
  border-radius: 5px;
  --background-color: var(--main-color);
  --color: var(--white);
  --padding: 5px 20px;
}
.modal-content p {
  display: flex;
  gap: 16px;
}
.modal-text {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.modal-buttons-row {
  display: flex;
  gap: 10px;
}

.modal-buttons-row .button {
  width: 100%;
}

@media (max-width: 576px) {
  .modal-buttons-row {
    flex-wrap: wrap;
  }
}
