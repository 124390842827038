.password-input-eye {
  position: absolute;
  right: 5px;
  top: 2px;
}
.password-tooltip-container {
  position: absolute;
  z-index: 10;
  bottom: calc(100% + 20px);
  left: 0;
  margin: auto;
  right: 0;
  width: fit-content;
  /* transition: max-height 0.3s; */
}
.password-tooltip {
  border-radius: 10px;
  padding: 10px;
  background-color: var(--main-color);
}
.password-tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-top: 20px solid var(--main-color);
  margin: auto;
}
.password-tooltip-content {
  color: var(--white);
  font-size: 0.8rem;
}
.password-tooltip-content svg {
  margin-left: 5px;
}
