.warning-parcours {
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  border: 3px solid #85000b;
  font-size: 1.3rem;
  color: #85000b;
  font-family: Outfit-Bold;
  margin-bottom: 1rem;
}
.retraitement-button {
  width: 40%;
  border-radius: 6px;
  margin: auto;
  margin-top: 1rem;
  padding: 40px 15px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 2px 3px 6px grey;
  margin-bottom: 1rem;
  position: relative;
  transition: background-color 0.3s;
}
.retraitement-button:hover {
  background-color: white;
}
.retraitement-button:hover span {
  color: var(--color);
}
.retraitement-button span {
  color: white;
  text-align: center;
}
.retraitement-button svg {
  width: 50px;
}
.shine-effect {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.03) 1%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(255, 255, 255, 0.85) 70%,
    rgba(255, 255, 255, 0.85) 71%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  animation: 3s 1s infinite move;
}
.recap-ebe {
  display: flex;
  padding: 10px;
  padding-top: 20px;
  flex-direction: column;
  gap: 8px;
}
.recap-row {
  display: flex;
  justify-content: space-between;
}
.recap-bold p {
  font-size: 1.5rem;
  font-family: Outfit-Bold;
}
.recap-header,
.recap-header-first,
.recap-header-last {
  color: var(--grey);
  height: 45px;
  background-color: var(--light-grey);
  border-radius: 5px;
  width: 150px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recap-header:not(.recap-header-first, .recap-header-last) {
  font-size: 40px;
}
.recap-header-first {
  font-family: Outfit-Bold;
}
.recap-header-last {
  width: 100%;
  height: auto;
  font-family: 'Outfit-Bold';
  padding: 10px;
  margin: 20px 0;
  gap: 1rem;
}
.minus,
.minus-plus {
  background-color: var(--grey);
  width: 30px;
  height: 5px;
  border-radius: 2px;
}
.minus-plus {
  transform: rotate(90deg);
  position: absolute;
}
.recap-legend {
  color: var(--secondary-color);
  font-family: Poppins-Medium;
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.recap-last-row p {
  color: var(--white);
}
.recap-last-row {
  background: var(--main-color);
  padding: 20px 15px;
  position: relative;
  left: -15px;
  width: calc(100% + 30px);
  border-radius: 15px;
}

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }
  5%,
  80% {
    opacity: 0;
  }
  48% {
    opacity: 0.2;
  }

  100% {
    left: 100%;
  }
}

@media (max-width: 992px) {
  .retraitement-button {
    width: 90%;
  }
  .retraitement-button svg {
    display: none;
  }
}

@media (max-width: 576px) {
  .recap-ebe p {
    font-size: 0.9rem;
    max-width: 60%;
  }
  .recap-ebe p:last-child {
    max-width: 40%;
  }
  .recap-header-last svg {
    display: none;
  }
}
