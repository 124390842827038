.search-bar-container {
  width: var(--width);
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
  min-width: 400px;
}
.search-bar-container input {
  border: none;
  padding: 10px 45px;
  border-radius: 8px;
  width: 100%;
  background-color: var(--background-color);
  transition:
    max-width 0.3s,
    box-shadow 0.3s;
}
.search-bar-container.pinned input {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
.search-bar-container svg {
  position: absolute;
  left: 14px;
}
.search-bar-container input:focus {
  outline-color: var(--main-color);
}
.search-bar-container .searchbar-cross:hover {
  --color: var(--grey) !important;
}
.search-bar-container .searchbar-cross {
  left: unset;
  right: 14px;
  transition: right 0.3s;
}

@media (max-width: 1200px) {
  .search-bar-container {
    min-width: 70%;
    width: 100%;
    max-width: var(--container-width);
    left: auto;
  }
  .search-bar-container input {
    max-width: 100%;
  }
  .search-bar-container.pinned .searchbar-cross {
    right: 14px;
  }
  .search-bar-container.pinned input {
    max-width: 100%;
  }
  .sticky-searchbar-container .search-bar-container .searchbar-cross {
    right: calc(15% + 14px);
    left: unset;
  }
}

@media (max-width: 576px) {
  .search-bar-container {
    padding: 0 10px;
  }
  .search-bar-container .searchbar-cross {
    right: 20px !important;
  }
  .search-bar-container input {
    max-width: 100%;
    padding-left: 40px;
  }
  .search-bar-container svg,
  .sticky-searchbar-container .search-bar-container svg {
    left: 20px;
  }
}
