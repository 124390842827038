.banner-pdf-container {
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  background-color: var(--main-color);
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 20px;
  min-height: 74px;
}
.banner-pdf-container h2 {
  color: var(--text-secondary-color);
  font-size: 1.8rem;
  font-weight: 700;
}
