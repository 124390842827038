.estimations-range-container-pdf {
  display: flex;
  align-items: center;
  margin-top: 10px !important;
  justify-content: center;
}
.side-estimation-range-container,
.center-estimation-range-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.estimation-range-value {
  padding: 10px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  font-size: 1.1rem;
  border: 3px solid var(--main-color);
  color: var(--main-color);
  font-weight: bold;
}
.estimation-range-subtext {
  height: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 7px;
}
.estimation-range-subtext svg {
  margin-left: 5px;
  margin-right: 5px;
}
.center-estimation-range-container .estimation-range-value {
  font-size: 1.8rem;
  background-color: var(--main-color);
  color: var(--text-secondary-color);
}

/* value-fond-commerce class */

.value-fond-commerce .estimation-range-value {
  border-color: transparent;
  background-color: transparent;
  color: var(--main-color);
  font-weight: bold;
}
.value-fond-commerce .center-estimation-range-container .estimation-range-value {
  position: relative;
}
.value-fond-commerce .center-estimation-range-container .estimation-range-value:before {
  content: '';
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: -5px;
  left: 0;
  background: linear-gradient(to left, transparent, var(--main-color), transparent);
}
.value-fond-commerce .side-estimation-range-container {
  gap: 0;
}
.value-fond-commerce .estimation-range-value {
  padding-top: 0;
  padding-bottom: 0;
}

/* value-estimation-details class */

.value-estimation-details .estimation-range-value {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px;
}
.value-estimation-details .center-estimation-range-container .estimation-range-value {
  background-color: var(--secondary-color);
  color: var(--text-secondary-color);
  font-size: 1.3rem;
  padding: 10px;
}
.value-estimation-details.estimations-range-container-pdf {
  gap: 40px;
}
.value-estimation-details .estimation-range-subtext {
  display: flex;
  gap: 4px;
}
.value-estimation-details .estimation-range-subtext:first-child {
  height: 0;
}
