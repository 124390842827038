.header-container {
  padding: 20px 15px 15px;
  z-index: 1000;
  position: relative;
}
.header-buttons-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.navbar-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 0 80px;
  align-items: center;
  width: 100%;
}
.navbar-container li {
  text-align: center;
  position: relative;
  height: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar-container li p,
.navbar-container li p > * {
  transition: font-family 0.3s;
}
.navbar-container li p:before,
.navbar-container li p > *:before {
  display: block;
  content: attr(custom-title);
  font-family: Outfit-Bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.navbar-container li:hover p,
.navbar-container li:hover p > * {
  font-family: Outfit-Bold;
  color: var(--secondary-color);
}
.navbar-content-arrow {
  margin: 0 0 0 -10px;
  position: relative;
  --color: var(--secondary-color) !important;
  opacity: 0;
  left: -15px;
  transition:
    opacity 0.3s,
    left 0.3s;
}
.navbar-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  top: 40px;
  left: -70px;
  background-color: var(--creamy-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition:
    max-height 0.3s,
    opacity 0.5s;
  z-index: 1000;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.35);
}
.navbar-content a:hover {
  color: var(--secondary-color);
}
.navbar-content a:hover .navbar-content-arrow {
  opacity: 1;
  left: 0;
}
.navbar-content a {
  display: flex;
  padding: 10px 20px;
  gap: 20px;
  align-items: center;
  transition: color 0.3s;
}
.logo-container {
  display: flex;
  align-items: center;
  transition: max-height 0.3s;
}
.logo-container svg {
  object-fit: contain;
}
.opened-header-section {
  opacity: 1;
}
.header-bracket {
  display: none;
}
.mobile-header-buttons {
  display: none;
}
.homepage-header .account-button {
  background-color: var(--grey) !important;
  border-color: var(--grey) !important;
  color: var(--grey) !important;
}
.header-user-icon {
  cursor: pointer !important;
  --color: var(--white) !important;
}
.homepage-header .account-button:hover {
  background-color: var(--grey) !important;
  color: var(--grey) !important;
}
.account-button {
  position: relative;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;
  font-family: Outfit-Bold;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  color: var(--white) !important;
}
.account-button:hover {
  background-color: var(--creamy-white) !important;
  color: var(--grey) !important;
}
.account-button:hover .header-user-icon {
  --color: var(--main-color) !important;
}
.account-button-container {
  cursor: pointer;
  display: flex;
  gap: 12px;
}
.account-nav .header-deconnexion-link {
  color: var(--red);
}
.account-nav {
  right: -10px;
  left: unset;
  top: 55px;
}
.header-logo {
  transform: scale(1.5);
  transform-origin: left;
}

@media (max-width: 1200px) {
  .header-logo {
    transform: scale(1);
  }
  .account-button {
    width: 40px;
    height: 40px;
  }
  .navbar-content {
    left: auto;
  }
  .header-container * {
    font-size: 1.5rem;
  }
  .header-rounded-div {
    top: -5px;
  }
  .opened-header .header-rounded-div {
    display: none;
  }
  .header-background {
    transition: background-color 0.3s;
    background-color: var(--main-color);
  }
  .header-background-opened {
    background-color: var(--creamy-white);
  }
  .logo-container svg {
    object-fit: unset;
  }
  .logo-container {
    overflow: hidden;
    transition: max-width 0.8s;
    display: block;
  }
  nav {
    min-height: calc(100vh - 35px);
    justify-content: flex-start !important;
  }
  .header-bracket {
    display: inline;
    margin-left: 15px;
    transition: transform 0.3s !important;
  }
  .header-container {
    transition:
      max-height 0.6s,
      background-color 0.3s;
    height: 101vh;
    overflow: hidden;
  }
  .closed-header {
    max-height: 100px;
    background-color: var(--main-color);
  }
  .header-container nav {
    gap: 30px;
  }
  .opened-header .logo-container {
    max-width: 400px;
  }
  .header-container nav,
  .navbar-container {
    flex-direction: column;
  }
  .navbar-container {
    gap: 20px;
    margin: 0;
    margin-top: 15px;
    align-items: flex-start;
  }
  .opened-header {
    max-height: 101vh;
    background: var(--creamy-white);
  }
  .opened-header .mobile-header-buttons {
    right: 20px;
  }
  .mobile-header-buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    transition: right 0.3s;
    align-items: center;
    height: 60px;
  }
  .mobile-header-buttons .button {
    transition:
      background-color 0.3s,
      color 0.3s,
      opacity 0.3s;
    opacity: 1;
  }
  .burger-menu {
    width: 30px;
    height: 20px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
  }
  .burger-menu div {
    height: 3px;
    width: 100%;
    background-color: var(--white);
    border-radius: 2px;
    transition: background-color 0.3s;
  }
  .opened-header .burger-menu div {
    background-color: var(--main-color);
  }
  .burger-menu div {
    transition: all 0.3s;
  }
  .opened-header .mobile-header-buttons .button {
    opacity: 0;
  }
  .opened-header .burger-menu {
    justify-content: center;
    position: relative;
  }
  .opened-header .burger-menu div {
    display: none;
  }
  .opened-header .burger-menu div:first-child {
    display: block;
    position: absolute;
    transform: rotate(45deg);
    transform-origin: center;
  }
  .opened-header .burger-menu div:last-child {
    display: block;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: center;
  }
  .opened-header .header-buttons-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .opened-header .account-button-container > svg {
    height: 15px;
    width: 15px;
    margin-top: 11px;
  }
  .opened-header .header-buttons-container .flex {
    flex-direction: row-reverse;
  }
  .navbar-container li {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  .navbar-content {
    position: relative;
    opacity: 1;
    top: 0;
    box-shadow: none;
  }
  .navbar-content a:hover {
    color: var(--secondary-color);
  }
  .navbar-content a {
    padding: 5px 40px;
  }
  .navbar-container li p,
  .navbar-container li p > * {
    font-size: 2rem;
  }
  .navbar-container li:hover p,
  .navbar-container li:hover p > * {
    font-family: Outfit-Medium;
    transition: font-family 0.3s;
  }
  .mobile-opened-index {
    font-family: Outfit-Bold !important;
  }
  .mobile-opened-index .header-bracket {
    transform: rotate(0) !important;
  }
}

@media (max-width: 992px) {
  .logo-container {
    max-width: 85px;
  }
}

@media (max-width: 576px) {
  .account-button {
    width: 35px;
    height: 35px;
  }
  .header-bracket {
    width: 12px;
    margin-left: 10px;
  }
  .logo-container {
    max-height: 43px;
  }
  .closed-header {
    max-height: 80px;
  }
  .mobile-header-buttons {
    height: 40px;
  }
  .navbar-container {
    gap: 5px;
  }
  .navbar-content-arrow {
    display: none;
  }
  .navbar-container li p,
  .navbar-container li p > *,
  .header-container * {
    font-size: 1.1rem;
  }
  .navbar-content a {
    font-size: 1rem !important;
  }
  .header-container nav {
    gap: 10px;
  }
  .navbar-content a {
    padding: 5px 10px;
    gap: 6px;
    max-width: calc(100vw - 30px);
  }
  .navbar-content svg {
    min-width: 30px;
  }
}
