.hr-separator {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 80px;
  margin-right: 80px;
  border: none;
  background: linear-gradient(to right, transparent, var(--secondary-color), transparent);
  height: 4px;
  width: calc(100% - 160px);
}
