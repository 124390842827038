.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}
.my-auto {
  margin: auto 0 !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.m-auto {
  margin: auto !important;
}

.mx-lg {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.mx-xl {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
.mx-xxl {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
.mx-md {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.mx-sm {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mr-lg {
  margin-right: 30px !important;
}
.mr-xl {
  margin-right: 60px !important;
}
.mr-xxl {
  margin-right: 100px !important;
}
.mr-md {
  margin-right: 20px !important;
}
.mr-sm {
  margin-right: 10px !important;
}

.ml-lg {
  margin-left: 30px !important;
}
.ml-xl {
  margin-left: 60px !important;
}
.ml-xxl {
  margin-left: 100px !important;
}
.ml-md {
  margin-left: 20px !important;
}
.ml-sm {
  margin-left: 10px !important;
}

.my-lg {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.my-xl {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.my-xxl {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
.my-md {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-sm {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mt-xl {
  margin-top: 60px !important;
}
.mt-xxl {
  margin-top: 100px !important;
}
.mt-2xxl {
  margin-top: 120px !important;
}
.mt-3xl {
  margin-top: 150px !important;
}
.mt-lg {
  margin-top: 30px !important;
}
.mt-md {
  margin-top: 20px !important;
}
.mt-sm {
  margin-top: 10px !important;
}

.mb-xl {
  margin-bottom: 60px !important;
}
.mb-xxl {
  margin-bottom: 100px !important;
}
.mb-lg {
  margin-bottom: 30px !important;
}
.mb-md {
  margin-bottom: 20px !important;
}
.mb-sm {
  margin-bottom: 10px !important;
}

.m-xl {
  margin: 60px !important;
}
.m-xxl {
  margin: 100px !important;
}
.m-lg {
  margin: 30px !important;
}
.m-md {
  margin: 20px !important;
}
.m-sm {
  margin: 10px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-xl {
  padding: 60px !important;
}
.p-lg {
  padding: 30px !important;
}
.p-md {
  padding: 20px !important;
}
.p-sm {
  padding: 10px !important;
}

.px-xl {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.px-lg {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.px-md {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-sm {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-xl {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.py-lg {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-md {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-sm {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pt-xl {
  padding-top: 60px !important;
}
.pt-lg {
  padding-top: 30px !important;
}
.pt-md {
  padding-top: 20px !important;
}
.pt-sm {
  padding-top: 10px !important;
}

.pb-xl {
  padding-bottom: 60px !important;
}
.pb-lg {
  padding-bottom: 30px !important;
}
.pb-md {
  padding-bottom: 20px !important;
}
.pb-sm {
  padding-bottom: 10px !important;
}

.pr-xl {
  padding-right: 60px !important;
}
.pr-lg {
  padding-right: 30px !important;
}
.pr-md {
  padding-right: 20px !important;
}
.pr-sm {
  padding-right: 10px !important;
}

.pl-xl {
  padding-left: 60px !important;
}
.pl-lg {
  padding-left: 30px !important;
}
.pl-md {
  padding-left: 20px !important;
}
.pl-sm {
  padding-left: 10px !important;
}

.gap-xxl {
  gap: 100px !important;
}
.gap-xl {
  gap: 60px !important;
}
.gap-lg {
  gap: 30px !important;
}
.gap-md {
  gap: 20px !important;
}
.gap-sm {
  gap: 10px !important;
}
