.text-hr-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: Outfit-Bold;
  color: var(--main-color);
  margin-bottom: 15px;
  align-items: flex-start;
}

.text-hr-container h3 {
  font-size: 1.5rem;
  color: var(--main-color);
  font-weight: bold;
}

.text-hr-side {
  width: 450px;
  height: 3px;
  border-radius: 3px;
  bottom: -6px;
  background: linear-gradient(-90deg, var(--main-color), transparent 100%);
}
