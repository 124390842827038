* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
  font-size: 1rem;
  list-style-type: none;
  font-family: Outfit-Medium;
  font-style: normal;
}
:root {
  color-scheme: only light;
}
::-webkit-scrollbar {
  width: 14px;
  border-radius: 15px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 15px;
  width: 14px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 5px;
}
::selection {
  background: var(--main-color);
  color: var(--white);
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--creamy-white);
  min-height: 100vh;
  overflow-x: hidden;
}
svg,
image {
  vertical-align: middle;
}
.page-container {
  min-height: calc(100vh - 550px);
}
.loading-page .page-container {
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow: hidden;
}
.non-scrollable-body {
  overflow: hidden !important;
  margin-right: var(--scrollbar-width);
}

a,
ul {
  text-decoration: none;
}
a {
  transition: color 0.3s;
}
a span {
  transition: color 0.3s;
}
a svg {
  transition:
    stroke 0.3s,
    fill 0.3s;
}
.link {
  color: var(--link-blue);
}
.link svg {
  --color: var(--link-blue);
}
.link:hover {
  color: var(--main-color);
}
.link:hover svg {
  --color: var(--main-color) !important;
}
.gold-link {
  color: var(--grey) !important;
}
.gold-link svg {
  --color: var(--grey) !important;
}
.gold-link:hover {
  color: var(--secondary-color) !important;
}
.gold-link:hover svg {
  --color: var(--secondary-color) !important;
}

p,
li {
  line-height: 1.6;
}
.d-none {
  display: none !important;
}
.h-none {
  max-height: 0 !important;
  padding: 0 !important;
}
.behind {
  z-index: -1 !important;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.align-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute !important;
}
.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.f-column {
  flex-direction: column !important;
}
.f-center {
  justify-content: center;
}
.s-between {
  display: flex;
  justify-content: space-between;
}
.scaleY-0 {
  transform: scaleY(0);
}
.scaleY-1 {
  transform: scaleY(1);
}
.bg-sky-blue {
  background-color: var(--secondary-color) !important;
}
.bg-dark-blue {
  background-color: var(--grey) !important;
}
.bg-gold {
  background-color: var(--grey) !important;
}
.bg-beige {
  background-color: var(--light-grey) !important;
}
.prevent-scrolling {
  height: 100%;
  overflow: hidden;
}
.block {
  display: block;
}
.hidden {
  visibility: hidden;
}

.sticky-container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.clickable-icon {
  cursor: pointer;
  transition: transform 0.2s;
}
.clickable-icon:active {
  transform: scale(1.1);
}
.cursor-pointer {
  cursor: pointer;
}
.with-transition {
  transition: all 0.3s;
}
